import React, { useCallback, useEffect, useState } from 'react';
import SEO from '../components/SEO';
import Layout from '../layouts/Layout';
import firebase from 'gatsby-plugin-firebase';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import Button from '../components/form-elements/Button';
import Input from '../components/form-elements/Input';
import { navigate } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 8px;
  }
`;

const SuccessMessage = styled.span`
  padding: 12px 8px;
  background: #c9e8cb;
  color: #1ca525;
`;

const ErrorMessage = styled.span`
  padding: 12px 8px;
  background: #f4adad;
  color: #e22424;
`;

interface ResetPasswordProps {
  actionCode: string;
  continueUrl: string;
  lang: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = function (props) {
  const { actionCode } = props;
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function check() {
      const firebaseEmail = await firebase
        .auth()
        .verifyPasswordResetCode(actionCode);

      setEmail(firebaseEmail);
    }

    check();
  }, [actionCode]);

  const handleResetClicked = useCallback(() => {
    async function reset() {
      await firebase.auth().confirmPasswordReset(actionCode, newPassword);
      setSuccess(true);
    }

    setError(null);
    reset().catch(e => setError(e.message));
  }, [actionCode, newPassword]);

  return (
    <Wrapper>
      <h1>Reset Password</h1>
      <span>for {email}</span>
      <Input
        type="password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        placeholder="New Password"
      />
      <Button
        buttonType="primary"
        onClick={handleResetClicked}
        disabled={success}
      >
        Reset
      </Button>
      {success && <SuccessMessage>Resetted!</SuccessMessage>}
      {error && <ErrorMessage>An error occurred</ErrorMessage>}
    </Wrapper>
  );
};

interface RecoverEmailProps {
  actionCode: string;
  lang: string;
}

const RecoverEmail: React.FC<RecoverEmailProps> = function (props) {
  const { actionCode } = props;
  const [error, setError] = useState(null);
  const [oldEmail, setOldEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  useEffect(() => {
    async function recover() {
      const info = await firebase.auth().checkActionCode(actionCode);

      const restoredEmail = info.data.email;
      setOldEmail(restoredEmail);

      await firebase.auth().applyActionCode(actionCode);
      setSuccess(true);
    }

    setError(null);
    recover().catch(e => setError(e));
  }, [actionCode]);

  const handleResetClicked = useCallback(() => {
    async function reset() {
      await firebase.auth().sendPasswordResetEmail(oldEmail);
    }

    setError(null);
    reset().catch(e => setError(e.message));
  }, [oldEmail]);

  return (
    <Wrapper>
      <h1>Recover Email</h1>
      {success && (
        <>
          <SuccessMessage>Email reverted to {oldEmail}!</SuccessMessage>
          <Button
            buttonType="primary"
            onClick={handleResetClicked}
            disabled={success}
          >
            Send Reset Password Email
          </Button>
        </>
      )}
      {error && <ErrorMessage>An error occurred</ErrorMessage>}
    </Wrapper>
  );
};

interface VerifyEmailProps {
  actionCode: string;
  lang: string;
}

const VerifyEmail: React.FC<VerifyEmailProps> = function (props) {
  const { actionCode } = props;

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function verify() {
      await firebase.auth().applyActionCode(actionCode);
      setSuccess(true);
    }

    setError(null);
    verify().catch(e => setError(e.message));
  }, [actionCode]);

  return (
    <Wrapper>
      <h1>Verification</h1>
      {success && <SuccessMessage>Email verified!</SuccessMessage>}
      {error && <ErrorMessage>An error occurred</ErrorMessage>}
    </Wrapper>
  );
};

const EmailPage: React.FC = function () {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const mode = params.get('mode');
  const actionCode = params.get('oobCode') ?? '';
  const continueUrl = params.get('continueUrl') ?? '';
  const lang = params.get('lang') || 'en';

  let child;

  switch (mode) {
    case 'resetPassword': {
      child = (
        <ResetPassword
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      );
      break;
    }
    case 'recoverEmail': {
      child = <RecoverEmail actionCode={actionCode} lang={lang} />;
      break;
    }
    case 'verifyEmail': {
      child = <VerifyEmail actionCode={actionCode} lang={lang} />;
      break;
    }
    default: {
      if (typeof window !== 'undefined') {
        navigate('/');
      }
      break;
    }
  }

  return (
    <Layout>
      <SEO title="Reset Password" />
      <Wrapper>{child}</Wrapper>
    </Layout>
  );
};

export default EmailPage;
